import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Cookies from 'js-cookie';

import actions from './actions';
import getters from './getters';
import modules from './modules';
import mutations from './mutations';
import state from './state';

Vue.use(Vuex);

const vuexCookie = new VuexPersistence({
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) => Cookies.set(key, state, {
    expires: 3,
  }),
  modules: ['auth'], // ,
  // filter: (mutation) => mutation.type == 'logIn' || mutation.type == 'logOut'
});
/* const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ navigation: state.navigation }),
  filter: (mutation) => mutation.type == 'addNavItem'
}) */

const store = new Vuex.Store({
  actions,
  getters,
  modules,
  mutations,
  state,
  plugins: [vuexCookie.plugin],
});

export default store;
