import axios from 'axios';

const baseUrl = process.env.VUE_APP_API;
//const baseUrl = localStorage['servidor']
const headers = {}

const send = (method) => (action, data = {}) => axios({
  method,
  url: baseUrl + action,
  headers,
  data,
})

export default {
  refreshToken: (auth) => (headers.Authorization = auth.auth ? `Bearer ${auth.auth}` : ''),
  get: (action) => send('get')(action, {}),
  put: (action, data = {}) => send('put')(action, data),
  post: (action, data = {}) => send('post')(action, data),
  delete: (action, data = {}) => send('delete')(action, data),
}