<template lang="pug">
  v-app
    core-toolbar(v-if="logado")
    core-view
</template>

<script>

export default {
  computed: {
    logado () {
      return !!this.$store.state.auth.user
    }
  }
}
</script>

<style lang="scss">
html{
  overflow:auto;
}
/*@import '@/styles/index.scss';*/
</style>
