<template lang="pug">
  v-toolbar(id="core-toolbar" color="primary" dark extended flat)
    v-spacer
    v-btn(icon to="/logout" style="z-index:9")
        v-icon mdi-export
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data: () => ({
    notifications: [],
    title: null,
    responsive: false,
    responsiveInput: false
  }),
  watch: {
    $route(val) {
      this.title = val.name;
    }
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onClick() {
      //
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    }
  }
}
</script>

<style>
#core-toolbar{
  position:fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  background:url('../../assets/bg.jpg');
  background-size:cover;
  background-position:top;
}
</style>
