import jwt from 'jsonwebtoken';

 const prevauth = jwt.sign(
   {},
   process.env.VUE_APP_TEMP_SECRET,
   {
     algorithm: process.env.VUE_APP_AUTH_ALG,
     expiresIn: process.env.VUE_APP_AUTH_EXP
   })

export default {
  prevauth,
  auth: null,
  user: null,
  status: {},
};
