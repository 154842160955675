<template lang="pug">
  div(class="text-center cs-loader")
    v-progress-circular(:size="50", color="green", indeterminate)
</template>

<script>
export default {};
</script>

<style>
  .cs-loader{
    margin-top:20px;
  }
</style>
