<template lang="pug">
  v-navigation-drawer(
      id="app-drawer"
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      permanent
      dark
      mini-variant-width="70"
      mobile-breakpoint="991"
      width="260"
      )
    v-list-item(tile)
      v-list-item-avatar(tile)
        img(src="@/assets/folha-icon.png", alt="ControlSoft")
      v-list-item-content
        v-list-item-title ControlSoft

      v-list-item-action
        v-btn(icon, @click.stop="mini = !mini", name="Menu")
          v-icon mdi-chevron-left

    v-list(nav, dense)
      v-list-item(
        v-for="(link, i) in itens",
        :key="i",
        :to="link.to",
        :active-class="color",
        class="px-2"
        )
        v-tooltip(right, dark=true, nudge-right=30)
          template(v-slot:activator="{ on }")
            v-list-item-action(v-on="on")
              v-icon {{ link.icon }}
            v-list-item-title(v-text="link.text")
          span {{ link.text }}

    template(v-slot:append)
      v-list(nav, dense)
        v-list-item
          v-list-item-avatar(color="grey")
            v-img(src="https://image.flaticon.com/icons/png/512/265/265674.png")
          v-list-item-content
            v-list-item-title {{usuario}}
            //- v-list-item-subtitle usuario@controlsoft.com.br
        v-list-item(to="/logout", color="tertiary")
          v-list-item-avatar
            v-icon mdi-export
          v-list-item-content 
            v-list-item-title Sair

</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  data: () => ({
    drawer: true,
    mini: true,
    right: null,
    usuario: '',
    email: '',
    itens: [
      // {
      //   to: '/home',
      //   icon: 'mdi-home',
      //   text: 'Home',
      // },
      {
        to: '/impostos',
        icon: 'mdi-currency-usd',
        text: 'Impostos',
      },
      // {
      //   to: '/bi',
      //   icon: 'mdi-view-dashboard',
      //   text: 'BI',
      // }, 
      /*{
        to: '/clientes',
        icon: 'mdi-account',
        text: 'Clientes',
      },
      {
        to: '/',
        icon: 'mdi-cog',
        text: 'Configurações'
      } */
    ],
    responsive: false,
  }),
  computed: {
    ...mapState('app', ['color']),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    items() {
      return this.$t('Layout.View.items');
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted);

    let getCookie = (name) => {
      const value = `; ${decodeURIComponent(document.cookie)}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    let vuex = JSON.parse(getCookie('vuex'))
    let userEncoded = (vuex.auth.auth).split('.')
    let user = atob(userEncoded[1])
    user = JSON.parse(user)
      
    this.usuario = user.usuario  
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onResponsiveInverted() {
      this.responsive = (window.innerWidth < 991);
    },
  },
};
</script>

<!--style lang="scss">
  #app-drawer {
    .v-list{
      height: 100%;
    }
    .v-list__tile {
      border-radius: 4px;
    }
  }
</style-->
