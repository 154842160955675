const getLink = (to) => ({ value }, item) => ({ value: item[value], to: to(item) })
const getBtnLink = (to, desc) => ({ value }, item) =>  ({ value: desc, to: to(item) })
const get = ({ value }, item) => ({ value: item[value] })
const get2 = ({ value }, item) => ({ value: item['razao'] })
const getDate = ({value}, item) => ({value: new Date(item[value]).toLocaleString()})
//const formatCNPJ = value => value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")

/*const formatCNPJ = ({value}, item) => {
  console.log(item)
  return ({value: 'teste'})
}*/
const formatCNPJ = ({value}, item) => ({value: 'teste'})

const fields = ({
  name, text, fn = get, sort = 0, link = 0, desc = 1, extra = 0, button = 0
}) => ({
  value: name, text, sortable: sort, link, get: fn, desc, extra, button
});

export default {
  clientes: [
    fields({name: 'cnpj', text: 'CNPJ', sort: 1, desc: 1, fn: formatCNPJ}),
    fields({name: 'razao', text: 'Razão Social', sort: 1, desc: 0}),
    fields({name: 'fantasia', text: 'Nome Fantasia', sort: 1, desc: 0})/*,
    fields({name: 'actions', text: 'Ações', sort: 0})*/
  ],
  romaneios: [
    fields({name: 'codigodb', text: 'Código', sort: 1, desc: 0 }),
    fields({name: 'numero', text: 'Número', sort: 1, desc: 0 }),
    fields({name: 'data_lcto', text: 'Data', sort: 1, desc: 0, fn: getDate }),
    fields({name: 'produto', text: 'Produto', sort: 1, desc: 0}),
    fields({name: 'nf_produtor', text: 'NF Produtor', sort: 1, desc: 0})
  ]
};
