<template lang="pug">
  
    v-card(class="elevation-0")

      v-col(cols="12" 
            sm="4" 
            md="12" 
            class="pb-3 pt-1")                  
        
        v-text-field(v-model="search" 
                     append-icon="mdi-magnify" 
                     label="Pesquisar" 
                     max-width="400px" 
                     single-line 
                     hide-details 
                     class="ma-0 pa-0")
      
      v-card(:max-height="tamanhoTela + 'px'" 
            style="overflow: auto" 
            elevation="0")

        v-data-table(v-model="vdadosSelecionados" 
                    color="green"  
                    mobile-breakpoint="50" 
                    class="elevation-0 label" 
                    hide-default-footer
                    :headers="vcabecalho"
                    :sort-by="vordenacao" 
                    :item-key="cabecalho[0].value" 
                    :items="vdados" 
                    :search="search" 
                    :items-per-page="1000" 
                    :show-select="vselecaoMultipla")

          template(v-slot:[`item.${cabecalho[0].value}`]="{ item }" )
            td(:v-money-format="item[cabecalho[0].value]") {{item[cabecalho[0].value]}}

          template(v-slot:no-results) < Busca não retornou resultados > 

          template(v-slot:no-data) 
          v-card(class="elevation-0 pt-16 mt-16") 
            div Nenhum registro encontrado para esta busca/exibição
            div Para incluir novos registros, clique em "+"

          template(v-slot:item.data-table-select="{ isSelected, select }")
            v-simple-checkbox(color="green" :value="isSelected" @input="select($event)" :ripple="false")

</template>

<script>

export default {
  props: {
    titulo:             { type: String,   default: '',        required: false  },
    cabecalho:          { type: Array,    default: null,      required: true   },
    dados:              { type: Array,    default: null,      required: true   },
    ordenacao:          { type: Array,    default: null,      required: false   },
    selecaoMultipla:    { type: Boolean,  default: false,     required: false  },
    tamanhoTela:        { type: Number,   default: 450,       required: false  },
  },
  data() {
    return {
      search: '',
      vtamanhoTela: 450,
      vabrirForm: true,
      vtitulo: this.titulo,
      vcabecalho: this.cabecalho,
      vdados: this.dados,
      vordenacao: this.ordenacao,
      vdadosSelecionados: [],
      vselecaoMultipla: this.selecaoMultipla,
      vvalue: this.value
    }
  },
  watch: {
    titulo () { 
      this.vabrirForm = this.titulo !== '' ? true : false 
      this.vtitulo = this.titulo
    },
    cabecalho () { 
      this.vcabecalho = this.cabecalho
    },
    dados () { 
      this.vdados = this.dados
    },
    ordenacao () { 
      this.vordenacao = this.ordenacao
    },
    selecaoMultipla () { 
      this.vselecaoMultipla = this.selecaoMultipla 
    },
    tamanhoTela () {
      this.vtamanhoTela = this.tamanhoTela
    },
    vdadosSelecionados() {
      this.$emit('input', this.vdadosSelecionados)
    }
  },
  created() {
    this.cabecalho.forEach(row => { row.cellClass = 'label'; row.sortable = true } )
  }
}
</script>

<style>

/* .label {
  color: "#f2f2f2";
  font-size: 12px !important;
} */
/* 
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
</style>