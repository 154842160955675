<template lang="pug">
  v-main(id="main-view" class="mx-auto" style="width:95%")
    core-messages
    v-fade-transition(mode="out-in")
      v-card(class="mx-auto" id="core-view" style="")
        v-card-title(class="ma-0 pb-0 text-h4 font-weight-light" v-if="logado")
        router-view(id="router-view")
</template>

<script>
export default {
  computed: {
    title() {
        return this.$route.name;
    },
    logado () {
      return !!this.$store.state.auth.user
    }
  }
}
</script>

<style>
#main-view{
  position:relative;
  height: calc(100hw - 290);
  margin-top: 60px;
}
</style>
