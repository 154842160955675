export default {
  success(state, message) {
    state.type = 'success';
    state.message = message;
  },
  info(state, message) {
    state.type = 'info';
    state.message = message;
  },
  warning(state, message) {
    state.type = 'warning';
    state.message = message;
  },
  error(state, message) {
    state.type = 'error';
    state.message = message;
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
};
