export default {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, { access_token, user }) {
    state.status = { loggedIn: true };
    state.auth = access_token;
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.auth = null;
    state.user = null;
  },
};
