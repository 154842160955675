<template lang="pug">

  v-flex(md6 v-if="alert.message")
    v-card(style="z-index: 1000; position: fixed; top: 15px; right: 15px; opacity: 0.9")
      v-alert(v-model="alert.visible"
              dense
              :type="alert.type"
              class="pt-3")
        v-row(align="center" class="pa-0 mt-0" style="margin-top: -18px !important")
          v-col(class="grow") {{alert.message}}
          v-col(class="shrink")
            v-btn(icon @click="fechar()") 
              v-icon mdi-close
      v-progress-linear(:value="percent" rounded height="6" color="white" style="margin-top: -22px")

</template>

<script>
export default {
  props: ['visible'],
  computed: {
    alert() {
      return this.$store.state.alert;
    },
  },
  data () {
    return ({
      percent: 0
    })
  },
  watch: {
    '$store.state.alert.message'() {
      this.addPercent()
    },
    $route() {
      this.$store.dispatch('alert/clear');
    },
  },
  methods: {
    fechar() {
      this.$store.dispatch('alert/clear')
    },
    addPercent() {
      let add = window.setInterval(() => {
        this.percent++
        if (this.percent === 100) { 
          this.$store.dispatch('alert/clear') 
          this.percent = 0 
          stopInterval() 
        }
      },60)

      let stopInterval = () => { clearInterval(add) }
      //setTimeout(() => { this.$store.dispatch('alert/clear') }, 8000)
    }
  } 
};
</script>
