import { auth as api } from '@/api'
import router from '@/router'

export default {
  async login({ dispatch, commit, state }, data) {
    api.refreshAuth(state)
    await api.login(data)
    .then((tk) => {
      dispatch('alert/info', 'Logado', { root: true })
      commit('loginSuccess', tk)
      router.push('/home')
    })
    .catch(() => {
      dispatch('alert/error', 'Usuário e/ou senha inválidos', { root: true })
      commit('loginFailure')
    })
  },
  logout({ commit }) {
    commit('logout')
  },
  verify({ state }) {
    if ((!state.auth) || (!state.user)) {
      router.push('/logout')
    }
  },
  checkLogin({ state }) {
    if ((state.auth) && (state.user)) {
      let user = JSON.parse( atob( ( (state.auth).split('.') )[1] ) )
      user.exp = user.exp * 1000
      if( (new Date()).getTime() > user.exp ) { router.push('/logout') } else { router.push('/home') }
    }
  },
}
