import baseApi from '@/api/base';
import fields from '@/api/fields';
import order from '@/utils/order';
import store from '@/store';

const refreshAuth = (auth) => baseApi.refreshToken(auth);

export const auth = {
  refreshAuth,
  login: (data) => baseApi.post('auth/login', data)
    .then(({ data }) => data),
  alterarSenha: (data) => baseApi.post('auth/alterarSenha', {...data, usuario: store.state.auth.user.cpfcnpj})
    .then(({ data }) => data)
};

export const clientes = {
  order: { sortBy: 'razaosocialcliente', descending: 1},
  fields: fields.clientes,
  getAll: () => baseApi.get('clientes').then(({ data }) => data)
}
export const romaneios = {
  refreshAuth,
  order: { sortBy: 'data_lcto', descending: 1},
  fields: fields.romaneios,
  getAll: (cnpj) => baseApi.get(`romaneios/${cnpj}`).then(({ data }) => data),
  getEstornados: (cnpj) => baseApi.get(`romaneios/estornados/${cnpj}`).then(({ data }) => data)
}

export default {
  fields,
  refreshAuth,
  auth,
  clientes/*,
  produtos,
  empresas,
  clientes,
  retencoes,
  retencOrigemAliq,
  operEstadual,
  operCfop,
  operEstoque*/
};
