<template lang="pug">
div
  CoreLoader(v-if="!loaded")


  v-data-table(
    v-if="loaded"
    :headers="fieldsVisiveis",
    :items="dados",
    :loading="!loaded",
    :footer-props="footerProps",
    :items-per-page-options="rowsPerPage",
    :search="search",
    :hide-default-footer="(dados.length < 10)",
    :item-key="chave")

    template(slot="headerCell", slot-scope="{ header }")
      span(class="subheading font-weight-regular text-success text--darken-3")
        |  {{ header.text }}

    //- template(v-slot:item.actions="{ item }")
      //v-btn(:icon="buttom.text === null" :rounded="buttom.text !== null" :outlined="buttom.text !== null" :small="buttom.text !== null" v-for="buttom in item.actions" :color="buttom.color" @click="retorno(buttom.name, item)") {{buttom.text}}
        //v-icon(class :right="buttom.text !== null") {{buttom.icon}}
           
    //- template(v-slot:item="props")
    //-   tr(@click="props.expanded = !props.expanded")
    //-     td(v-for="field in fieldsVisiveis", :key="field.value")
    //-       router-link(:to="field.get(field, props.item).to", v-if="field.link")
    //-         v-btn(v-if="field.button === 0 && field.link === 1" text)
    //-           | {{ field.get(field, props.item).value }}
    //-         span(v-if="!field.button && !field.link")
    //-           | {{ field.get(field, props.item).value }}
    //-       v-btn(v-if="field.button === 1 && field.link === 0" dense outlined color="red" x-small @click="retorno(field.get(field, props.item).to+'/'+field.value)") {{ field.get(field, props.item).to }} 
    //-         v-icon(color="red" small rigtht) {{ field.get(field, props.item).value }} 
    //-       span(v-if="field.button === 0 && field.link === 0" class="label pa-1")
    //-         | {{ field.get(field, props.item).value }}


    template(v-slot:no-results) < Busca não retornou resultados >

    template(v-slot:no-data) 
      v-card(class="elevation-0 pa-15") 
        div Nenhum registro encontrado
        v-btn(:to="addUrl" v-if="!!addUrl" color="success" outlined class="ma-2 white--text")
          | Adicionar 
          v-icon(right) mdi-plus-circle

    template(v-slot:items="props")
      tr(@click="props.expanded = !props.expanded")
        td(v-for="field in fieldsVisiveis", :key="field.value")
          router-link(:to="field.get(field, props.item).to", tag="a", v-if="field.link")
            | {{ field.get(field, props.item).value }}
          span(v-if="!field.link")
            | {{ field.get(field, props.item).value }}

    template(v-slot:expand="props", v-if="fieldsExtras.length")
      tr
        th(v-for="field in fieldsExtras" :key="field.value")
          | {{ field.text }}
      tr
        td(v-for="field in fieldsExtras" :key="field.value")
          router-link(:to="field.get(field, props.item).to", tag="a", v-if="field.link")
            | {{ field.get(field, props.item).value }}
          span(v-if="!field.link")
            | {{ field.get(field, props.item).value }}
</template>

<script>
export default {
  props: ['caption', 'fields', 'dados', 'loaded', 'order', 'addUrl', 'addSearch'],
  data: () => ({
    search: '',
    options: {},
    footerProps: {itemsPerPageText: 'Itens por página', itemsPerPageOptions: [100, { text: 'Todos', value: -1 }]},
    chave: '',
    rowsPerPage: [100, { text: 'Todos', value: -1 }],
    fieldsVisiveis: {},
    fieldsExtras: {}
  }),
  created() {
    this.fields.forEach(row => { row.cellClass = 'label'} )
    this.fieldsVisiveis = this.fields.filter((f) => !(f.extra))
    this.fieldsExtras = this.fields.filter((f) => f.extra)
    this.options = this.order
    this.chave = this.fields[0].value
    
  },
  methods: {
    retorno (comando, row) {
      this.$emit('comandoExecutar', {comando: comando, row: row})
    }
  }
};
</script>

<style>
  .margin-200{
    margin-top:200px;
  }
  .search:focus-within {
    width: 400px;
  }
  .search-width {
    width: 0 !important;
  }
  td.label{
    height:45px !important;
  }
</style>
