  
import Vue from 'vue'

Vue.directive('money-format', (el, { value }) => {
  el.innerHTML = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    minimumFractionDigits: 2
  }).format(value || 0)
})

Vue.directive('key-format', (el, { value }) => {
  console.warn(el)
  el.innerHTML = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 0
  }).format(value + 'teste' || 0)
})