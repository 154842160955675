<template lang="pug">

  div
    v-select(v-model="vretornoDados" 
            :items="vdados" 
            :item-text="vcabecalho" 
            multiple label="Selecione" 
            hide-details 
            dense 
            chips 
            small-chips 
            append-outer-icon="mdi-binoculars" 
            @click:append-outer="abrirFormBusca()")
    label {{ vtitulo }}

    Busca(v-model="vdadosSelecionados" :abrirForm="vBusca.abrirForm" :titulo="vBusca.titulo" :cabecalho="vBusca.cabecalho" :dados="vBusca.dados" :selecaoMultipla="vBusca.multiplaSelecao")


</template>

<script>

import Busca from './Busca'

export default {
  components: {
    Busca
  },
  props: {
    //value:             { type: Array,    default: null,                   required: true   },
    titulo:            { type: String,   default: 'informe um label',     required: true   },
    cabecalho:         { type: Array,    default: [],                     required: true   },
    tipoLista:         { type: String,   default: 'simples',              required: false  },
    dados:             { type: Array,    default: null,                   required: true   },
    multiplaSelecao:   { type: Boolean,  default: false,                  required: false  }
  },
  data () {
    return {
      vtitulo: this.titulo,
      vcabecalho: this.cabecalho,
      vtipoLista: this.tipoLista,
      vretornoDados: null,
      vdados: this.dados,
      vdadosSelecionados: [],
      vbuscaDados: this.buscaDados,
      vmultiplaSelecao: this.multiplaSelecao,
      vBusca: {
        titulo: '',
        cabecalho: '',
        dados: '',
        multiplaSelecao: false
      }
    }
  },
  watch: {
    titulo()              { this.vtitulo = this.titulo                      },
    cabecalho()           { this.vcabecalho = this.cabecalho                },
    tipoLista()           { this.vtipoLista = this.tipoLista                },
    dados()               { this.vdados = this.dados                        },    
    buscaDados()          { this.vbuscaDados = this.buscaDados              },
    multiplaSelecao()     { this.vmultiplaSelecao = this.multiplaSelecao    },
  },
  methods: {
    abrirFormBusca() {
      this.vBusca.titulo = ''
      this.vBusca.titulo = 'Busca de ' + this.vlabel
      this.vBusca.cabecalho = this.vcabecalho
      this.vBusca.dados = this.vdados
      this.vBusca.multiplaSelecao = this.vmultiplaSelecao
    }
  }

}
</script>