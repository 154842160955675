<template lang="pug">
  v-flex
    v-alert(v-bind="$attrs"
      :class="[`elevation-${elevation}`]"
      :value="value"
      class="v-alert--notification"
      style="z-index: 1000; opacity: 0.6"
      v-on="$listeners")

    v-snackbar(v-model="snackbar") Apena um teste

</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    elevation: {
      type: [Number, String],
      default: 6,
    },
    value: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return ({
      snackbar: true,
    })
  }
};
</script>

<style>
.v-alert--notification {
  border-radius: 4px !important;
  border-top: none !important;
  z-index: 1000
}
</style>
