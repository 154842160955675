import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import '@/components';
import '@/plugins';
import App from '@/App';
import store from '@/store';
import router from '@/router';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import './utils/directives';

sync(store, router);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
